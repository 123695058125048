import React from "react";
import { useTranslation } from "react-i18next";
import boka from "../../assets/images/boka.png";

const RegionInfo = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="flex justify-center items-center px-[100px] gap-8 h-[400px]">
        <div className="w-[600px]">
          <img src={boka} alt="" />
        </div>
        <div className="w-[865px] h-[170px]">
          <h4 className="font-[700] mb-[10px] mt-[-50px] text-[36px] text-#121221">
            {t("Bo'ka tumani haqida")}
          </h4>
          <p>
            <strong className="text-[20px]">Boʻka tumani</strong>— Toshkent
            viloyati tarkibidagi tuman. Viloyatning jan.gʻarbiy qismida
            joylashgan. 1943-yil 18-mayda tashkil etilgan. Shim.gʻarbda
            viloyatning Oqqoʻrgʻon, shim.sharqda Piskent, jan.da Bekobod
            tumanlari bilan, gʻarbda Sirdaryo viloyatining Oqoltin va Guliston
            tumanlari, sharqda Tojikiston Respublikasi bilan chegaradosh.
            Maydoni 0,59 ming km². Aholisi 111,8 ming kishi (2001). Boʻka tumani
            da 1 shahar (Boʻka), 8 qishloq fuqarolari yigʻini (Boʻston,
            Koʻkorol, Namuna, Ravot, Turon, Chigʻatoy, Qoraqoʻyli, Qoʻshtepa)
            bor. Markazi — Boʻka shahri
          </p>
        </div>
      </div>
    </div>
  );
};

export default RegionInfo;
